<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Candidates</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Import candidate</h4>
          <div class="add-outline">
            <a-button class="sapmle-download" type="primary">
              <a href="https://academy-assets-prod.s3-eu-west-1.amazonaws.com/candidate-sample-import.csv"><CloudDownloadOutlined /> Sample</a>
            </a-button>
            <a-button type="primary">
              <router-link :to="'/client/candidates'+loginAs"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="import-candidate">
        <div class="body">
          <a-upload-dragger
            v-model:fileList="fileList"
            name="file"
            :multiple="true"
            :action="imageUploadUrl"
            @change="handleChange"
            :headers="headers"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </a-upload-dragger>
          <div class="candidate-list" v-if="candidateData.length > 0">
            <a-table :columns="columns" :data-source="candidateData" rowKey="_id"
              :pagination="{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '40', '50']
              }"
            >
              <template #exist="{ text: exist }">
                <span>
                  <a-tag :color="exist ? 'red' : 'green'"> {{ exist ? 'Email exist' : 'Valid' }} </a-tag>
                </span>
              </template>
              <template #action="{ record, index }">
                <a-tooltip>
                  <template #title>Delete Client</template>
                  <DeleteOutlined @click="showDeleteConfirm(record, index)" :style="{ fontSize: '15px' }" />
                </a-tooltip>
              </template>
            </a-table>
            <div class="create-action">
              <a-button type="primary" :disabled="disableCreate" @click="selectCourse" :loading="loading">Select Course </a-button>
            </div>
          </div>
        </div>
      </a-row>
    </div>
    <div>
      <a-drawer title="Course List" placement="right" :closable="true" v-model:visible="visible" @ok="closeCourseListModal" class="import courselistDrawer" :maskClosable="false" :destroyOnClose="true" :width="500">
        <a-checkbox v-model:checked="selectAllCourse" :indeterminate="indeterminate" @change="onCheckAllChange">
          All Courses
        </a-checkbox>
        <a-checkbox-group v-model:value="selectedCourse" :options="availableCourse"/>
        <div class="footersec">
          <a-button type="primary" @click="importCandidates" :loading="loading">Assign &amp; Create Candidate</a-button>
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script lang="ts">
import { notification, Modal, message } from 'ant-design-vue'
import { defineComponent, ref, onMounted, createVNode, reactive, watch, toRefs } from 'vue'
import { HomeOutlined, ArrowLeftOutlined, InboxOutlined, DeleteOutlined, ExclamationCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons-vue'

import profileService from '../../../services/profile'
import commonServices from '../../../services/common'
import courseService from '../../../services/course'

import ICourse from '../../../interface/course'

import router from '../../../router'

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

const state = reactive({
  indeterminate: false,
  selectAllCourse: false,
  selectedCourse: [],
  selectedGroup: []
})

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    InboxOutlined,
    DeleteOutlined,
    CloudDownloadOutlined
  },
  setup () {
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_CSV_FILE_UPLOAD
    const clientId = ref<string>(commonServices.getCurrentProfile()._id)
    const candidateData = ref<Array<any>>([])
    const disableCreate = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'first_name'
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Phone',
        dataIndex: 'phone'
      },
      {
        title: 'Status',
        dataIndex: 'exist',
        slots: { customRender: 'exist' }
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    const availableCourse = ref([])
    const unassignedCourses = ref<Array<ICourse>>([])
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const handleChange = (info: FileInfo) => {
      const status = info.file.status
      if (status !== 'uploading') {
        candidateData.value = info.file.response
        const error = candidateData.value.filter(data => data.exist === true)
        if (error.length > 0) {
          disableCreate.value = true
        }
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const getUnassignedCourses = async () => {
      try {
        availableCourse.value = []
        const clientId = commonServices.getClientId()
        const sCourse = await courseService.getClientCoursesByType(clientId, 'Single')
        const gCourse = await courseService.getClientCoursesByType(clientId, 'Group')

        unassignedCourses.value = [...sCourse.data, ...gCourse.data]

        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const selectCourse = async () => {
      visible.value = true
    }
    const closeCourseListModal = async () => {
      visible.value = false
    }
    const onCheckAllChange = async (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    watch(() => state.selectedCourse, (val) => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.selectAllCourse = val.length === availableCourse.value.length
    })
    const importCandidates = async () => {
      try {
        loading.value = true
        const clientId = commonServices.getClientId()
        const data = {
          candidates: candidateData.value,
          courses: state.selectedCourse
        }
        await profileService.importCandidates(data, clientId)
        notify('Success', 'Candidates Imported Successfully', 'success')
        closeCourseListModal()
        router.push(`/client/candidates${loginAs.value}`)
        loading.value = false
        candidateData.value = []
        disableCreate.value = true
      } catch (error) {
        console.log('error', error)
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to remove this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          candidateData.value.splice(index, 1)
          const error = candidateData.value.filter(data => data.exist === true)
          if (error.length > 0) {
            disableCreate.value = true
          } else {
            disableCreate.value = false
          }
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(() => {
      console.log('mounted')
      getUnassignedCourses()
    })
    return {
      clientId,
      handleChange,
      fileList: ref([]),
      headers,
      imageUploadUrl,
      columns,
      candidateData,
      showDeleteConfirm,
      disableCreate,
      importCandidates,
      loading,
      loginAs,
      selectCourse,
      onCheckAllChange,
      closeCourseListModal,
      availableCourse,
      visible,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      color: #646464;
      font-family: "TT Norms Pro Medium";
      margin: 0;
    }
    .add-outline {
      float: right;
      .ant-btn {
        background-color: transparent;
        border-color: #38B6FF;
        color: #38B6FF;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        text-shadow: none;
        box-shadow: none;
        a {
          color: #38B6FF;
        }
      }
      .sapmle-download {
        margin-right: 20px;
      }
    }
  }
  .import-candidate {
    padding: 10px;
    .body {
      background: #ffffff;
      min-height: 200px;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      height: 100%;
      .ant-upload-drag {
        height: 40%;
      }
      .candidate-list {
        margin-top: 20px;
        .create-action {
          .ant-btn {
            background-color: transparent;
            border-color: #38B6FF;
            color: #38B6FF;
            border-radius: 5px;
            font-family: "TT Norms Pro Medium";
            text-shadow: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.import.courselistDrawer {
    .footersec {
      .ant-btn-primary {
        width: 200px;
      }
    }
  }
</style>
